import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import styles from '../styles/appbar.module.scss'
import { Avatar } from '@mui/material';

/**
 * AppBar is a simple menu system
 *
 * @version 1.0.0
 * @author [Danyal Ahmed]
 */
export default function Appbar() {


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar elevation={0} position="static" className={styles.barColor}>
        <Toolbar className={styles.toolbar}>
          <div className={styles.logoDivLeft} ><Avatar variant="square" src='/siteAttendanceLeft.svg' className={styles.logo}/></div>
          <div className={styles.logoDivRight} ><Avatar variant="square" src='/siteAttendanceRight.svg' className={styles.logoRight}/></div>
          <div className={styles.logoDivMRight} ><Avatar variant="square" src='/siteAttendanceTop.svg' className={styles.logoMRight}/></div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

