import styles from '../styles/body.module.scss'
import btnStyles from '../styles/buttons.module.scss'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ErrorIcon from '@mui/icons-material/Error';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import{BottomNavigation, BottomNavigationAction} from '@mui/material'

export default function Error() {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const homePage = () => {
    navigate('/');
  }

  return (

    <>
    <Box className={styles.errorHeader} >
      
        <h1>Page Not Found</h1>
        </Box>
        <Box className={styles.errorheader} >
        <div className={styles.successIcon}> 
        {<ErrorIcon fontSize="inherit"/>}
        </div>
        <h4>The page you were looking for does not exist. Please select the button below.</h4>
        </Box>
      <Box className={btnStyles.singleButtonContainer} >
              <Button startIcon={<HomeIcon />}  className={btnStyles.backSingleBtn} size="large" variant="contained" onClick={() => goBack()}>Back to start</Button>

          </Box>
          <Box className={btnStyles.bottomNavContainer}>
              <BottomNavigation sx={{
                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  height:'70px',
                  zIndex: '1000'
              }} showLabels>
                  <BottomNavigationAction id="navBackButton" label='Back' icon={<ArrowBackIosNewIcon />} onClick={() => goBack()} />
              </BottomNavigation>
          </Box>
    </>
  );
}

