import { useState, useLayoutEffect, useEffect } from 'react';
import styles from '../styles/body.module.scss'
import btnStyles from '../styles/buttons.module.scss'
import Button from '@mui/material/Button';
import axios from '../api/axios';
import useAxiosFunction from '../hooks/useAxiosFunction';
import {Box, TextField} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { useNavigate, useParams } from 'react-router-dom';

export default function SitePage({siteId, setSiteId, siteName, setSiteName, userId, setUserId}) {

  const navigate = useNavigate();

  const [response, error, loading, axiosFetch] = useAxiosFunction();

  const [userIdError, setUserIdError] = useState(false);
  const [userIdText, setUserIdText] = useState('');

  const [result, setResult] = useState(false);
  const [checkResult, setCheckResult] = useState(false);


  const params = useParams();
    if((siteId == null)||(siteId == 0) || (siteId != params.id)){ 
      setSiteId(params.id);
    }

  const handleUserIDChange = (newValue) => {
      setUserId(newValue);
  };


  useLayoutEffect(() => {
    populateSiteName();
  }, [siteId]);


  useLayoutEffect(() => {
    populateAction();
  }, [result]);

  useLayoutEffect(() => {
    checkAction();
    return()=>{
      setCheckResult(false);
    }
  }, [checkResult]);

    const populateSiteName = async e => {

      if((siteId == null)||(siteId == 0) || (siteId != params.id)){ 
        setSiteId(params.id);
        return;
      }


      const bodyObj = JSON.stringify({
          "id":siteId

      });
        try {
            await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/getSiteName',
                requestConfig: {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: bodyObj
                }

            })
        } catch (err) {
            console.log(err);

        }
        setResult(true);
    }

  function populateAction(){
    if(response.statusCodeValue === 200){
      setSiteName(response.body.siteName);
    }
  }


  function checkAction(){
    if(response.status === 200){
      let res = response.userSiteStatus;

      if(res === false){
        setUserIdText('This induction code cannot be found on this site. Make sure you are joined and authorised to site.');
        setUserIdError(true);
      }
      else{
        setUserIdText('');
        setUserIdError(false);
        navigate("/user")

      }


    }


  }


  const nextPage = async e  =>{
    if(userId !== "" && userId!== 0 && userId !== null){
    try {
      await axiosFetch({
        axiosInstance: axios,
        method: 'post',
        url: '/checkUserSiteStatus',
        requestConfig: {
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            "siteId":siteId,
            "id":userId
        }
        }

      })
    } catch (err) {
      console.log(err);

    }

  setCheckResult(true);



    }
    else{
      //display error message
      if(userId=== null || userId === "" || userId === 0){
        setUserIdText('Please provide your Dandara induction code');
        setUserIdError(true);
      }

    }
  }


  return (
    <div>
      <Box className={styles.titleH1Top} >
        <h1>Welcome to { siteName ? siteName : 'Site Name'}</h1>
        </Box>
        <Box className={styles.questionH1}>
              <h1>Dandara Induction Code</h1>
              </Box>
        <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={userIdError}>
                 <TextField error={userIdError} fullWidth id="outlined-basic" label="Dandara Induction Code" variant="outlined" onChange={e => handleUserIDChange(e.target.value)}  />
                 <FormHelperText>{userIdText}</FormHelperText>
              </FormControl>
              </Box>
        <Box className={btnStyles.buttonContainer} >
            <Button className={btnStyles.btn} size="large" variant="contained"
             onClick={()=> nextPage("SignInOut")} 
             >Sign In/Out</Button>


      </Box>
        
    </div>
  );
}

