import { useState, useEffect} from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import {Routes, Route, useNavigate } from 'react-router-dom';
import AppBar from './components/AppBar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Home from './routes/Home';
import SitePage from './routes/SitePage';
import UsersInformation from './routes/UsersInformation';
import ActionPage from './routes/ActionPage';
import DrivingPage from './routes/DrivingPage';
import DrivingQuestionsPage from './routes/DrivingQuestionsPage';
import Success from './routes/Success';
import Error from './routes/ErrorPage';
import SiteRules from './routes/SiteRules';


function App() {
  const theme = createTheme({
    palette: {
      primary: {
        light:'#72c8bd',
        main: '#4FBAAD',
        dark:'#47a79c'
      },
      secondary:{
        light:'#ed7148',
        main:'#E94E1B',
        dark:'#a33612',
      },
      error:{
        light:'#ed7148',
        main:'#E94E1B',
        dark:'#a33612',
      }
    },
  });

 
  const [siteId, setSiteId] = useState(null);
  const [siteName, setSiteName] = useState(null);

  const [userId, setUserId] = useState(null);

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [operativeEmployer, setOperativeEmployer] = useState(null);
  const [drivingToSite, setDrivingToSite] = useState(false);
  const [asDriver, setAsDriver] = useState(false);

  const [isValid, setIsValid] = useState(false);

  const [signIn, setSignIn] = useState(false);
  const [signOut, setSignOut] = useState(false);
  const [isDrivingToday, setIsDrivingToday] = useState(false);

  const [askDrivingQue, setAskDrivingQue] = useState(false);

  const [buttonToShow, setButtonToShow] = useState(null);

  const [drivingToSiteNew, setDrivingToSiteNew] = useState(false);
  const [asDriverNew, setAsDriverNew] = useState(false);
  const [registrationNumber, setRegistrationNumber] = useState(null);

  const [transportType, setTransportType]  = useState(null);
  const [transportSize, setTransportSize]  = useState(null);
  const [fuelType, setFuelType] = useState(null);

  return (

    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <AppBar />
          <Routes>
            <Route path="/" element={<Home siteId={siteId} setSiteId={setSiteId} />} />
            <Route path="/site/:id" element={<SitePage siteId={siteId} setSiteId={setSiteId} siteName={siteName} setSiteName={setSiteName} userId={userId} setUserId={setUserId} />} />
            <Route path="/user" element={<UsersInformation siteId={siteId} userId={userId} setUserId={setUserId} firstName={firstName} setFirstName={setFirstName} lastName={lastName} setLastName={setLastName} operativeEmployer={operativeEmployer} setOperativeEmployer={setOperativeEmployer} drivingToSite={drivingToSite} setDrivingToSite={setDrivingToSite} asDriver={asDriver} setAsDriver={setAsDriver} isValid={isValid} setIsValid={setIsValid} buttonToShow={buttonToShow} setButtonToShow={setButtonToShow} askDrivingQue={askDrivingQue} setAskDrivingQue={setAskDrivingQue} signIn={signIn} setSignIn={setSignIn} signOut={signOut} setSignOut={setSignOut} setIsDrivingToday={setIsDrivingToday}/>} />
            <Route path="/action" element={<ActionPage signIn={signIn} setSignIn={setSignIn} signOut={signOut} setSignOut={setSignOut} siteId={siteId} userId={userId} buttonToShow={buttonToShow} setButtonToShow={setButtonToShow} askDrivingQue={askDrivingQue} setAskDrivingQue={setAskDrivingQue} setIsDrivingToday={setIsDrivingToday}/>} />
            <Route path="/driving" element={<DrivingPage userId={userId} drivingToSite={drivingToSite} asDriver={asDriver} isDrivingToday={isDrivingToday} setIsDrivingToday={setIsDrivingToday} />} />
            <Route path="/driving-questions" element={<DrivingQuestionsPage userId={userId} drivingToSite={drivingToSite} asDriver={asDriver} drivingToSiteNew={drivingToSiteNew} setDrivingToSiteNew={setDrivingToSiteNew} asDriverNew={asDriverNew} setAsDriverNew={setAsDriverNew} registrationNumber={registrationNumber} setRegistrationNumber={setRegistrationNumber} transportType={transportType} setTransportType={setTransportType} transportSize={transportSize} setTransportSize={setTransportSize} fuelType={fuelType} setFuelType={setFuelType} />} />
            <Route path="/site-rules" element={<SiteRules siteId={siteId} userId={userId} isDrivingToday={isDrivingToday} setIsDrivingToday={setIsDrivingToday} signIn={signIn} signOut={signOut} />} />
            <Route path="/success" element={<Success siteId={siteId} siteName={siteName} firstName={firstName} lastName={lastName} signIn={signIn} signOut={signOut} />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </ThemeProvider>

      </LocalizationProvider>
    </StyledEngineProvider>

  );
}

export default App;
