import { useState, useLayoutEffect } from 'react';
import styles from '../styles/body.module.scss'
import btnStyles from '../styles/buttons.module.scss'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HomeIcon from '@mui/icons-material/Home';

import { useNavigate } from 'react-router-dom';
import{BottomNavigation, BottomNavigationAction} from '@mui/material'


export default function Success({siteId, siteName, firstName, lastName, signIn, signOut}) {
  const navigate = useNavigate();

  const [action, setAction] = useState('');


  useLayoutEffect(() => {
    if(signIn == true){
        setAction("signed in to");
    }
    else if(signOut == true){
        setAction("signed out of");
    }
  }, [signIn, signOut]);

 
  const homePage = () => {
    navigate("/site/"+siteId)
  }


  return (

    <>
      <Box className={styles.successHeader} >
        <h1>Thank you, {firstName} {lastName}</h1>
        </Box>
        <Box className={styles.header} >
        <div className={styles.successIcon}> 
        {<CheckCircleOutlineIcon fontSize="inherit"/>}
        </div>
        <h4 className={styles.successText}>You have now {action} {siteName}</h4>
        </Box>



        <Box className={btnStyles.singleButtonContainer}>
        <Button startIcon={<HomeIcon />} className={btnStyles.nextSingleBtn} size="large" variant="contained"
          onClick={() => homePage()} >Next Worker</Button>
      </Box>
      <Box className={btnStyles.bottomNavContainer}>
            <BottomNavigation sx={{width:'100%',
             position:'fixed', 
             bottom:0,
             height:'70px',
             zIndex: '1000'
             }} showLabels>
              <BottomNavigationAction  id="navNextButton" label='Next Worker' icon={<HomeIcon />} onClick={()=> homePage()}/>
            </BottomNavigation>
      </Box>

    </>
  );
}

