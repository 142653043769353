import { useState, useLayoutEffect} from 'react';
import styles from '../styles/body.module.scss'
import btnStyles from '../styles/buttons.module.scss'
import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import axios from '../api/axios';
import useAxiosFunction from '../hooks/useAxiosFunction';
import CircularProgress from '@mui/material/CircularProgress';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';

export default function ActionPage({signIn, setSignIn, signOut, setSignOut, siteId, userId, buttonToShow, setButtonToShow, askDrivingQue, setAskDrivingQue, setIsDrivingToday}) {

  const navigate = useNavigate();
  const [response, error2,loading, axiosFetch] = useAxiosFunction();
  const [result, setResult] = useState(false);
  const [progress, setProgress] = useState(false);
  
  const goBack = () => {
    navigate("/site/"+siteId)
  }


  const [submitClicked, setSubmitClicked] = useState(false);
  useLayoutEffect(() => {
    if(result){
      submitAction();
    }
    
  }, [result])

  const nextPage = (res) =>{
    //if sign in navigate to driving question, else navigate to site page
    setProgress(true);
    setSubmitClicked(true);

    if(res === "signIn"){
      setSignIn(true);
      setSignOut(false);
      setProgress(false);

      if(askDrivingQue){
        navigate("/driving");
      }
      else{
        setIsDrivingToday(false);
        navigate("/site-rules");
      }

      

    }
    else if(res === "signOut"){
      setSignOut(true);
      setSignIn(false);

      //record attendance sign out
      submit();
    }

  }

  const submit = async e => {
    const bodyObj = JSON.stringify({
      "userId":userId,
      "siteId":siteId,
      "isDriving":false,
      "signIn":false,
      "signOut":true,
      "signedBy":userId
    });

    try {
      await axiosFetch({
        axiosInstance: axios,
        method: 'post',
        url: '/recordAttendance',
        requestConfig: {
          headers: {
            'Content-Type': 'application/json',
          },
          data: bodyObj
        }

      })
    } catch (err) {
      console.log(err);

    }
    setResult(true);
  }

  function submitAction() {
    console.log("RESPONSE: "+response)
    if (response.status === 200) {
      setProgress(false);
      navigate("/success");
    }
  }


  return (
    <div>
      <Box className={styles.questionH1Top} >
        <h1>Select your action:</h1>
        </Box>
        
        <Box className={btnStyles.buttonContainer} >
        {buttonToShow == 'sign_in' &&
            <Button disabled={progress} className={btnStyles.btn} size="large" variant="contained"
             onClick={()=> nextPage("signIn")} 
             >Sign In</Button> }
        {buttonToShow == 'sign_out' &&
       <> <h4 className={styles.signedInText}>You are currently signed in.</h4>
            <Button disabled={progress} className={btnStyles.btn} size="large" variant="contained"
             onClick={()=> nextPage("signOut")} 
             >Sign Out</Button>
             <Button disabled={progress}  startIcon={<HomeIcon />} className={btnStyles.backSingleBtn} size="large" variant="contained" onClick={() => goBack()}>Back to start</Button>
             </>}

      </Box>
      <Box className={styles.progressBar}>
        {progress && <CircularProgress size="5rem"/>} 
        </Box>
        
    </div>
  );
}

