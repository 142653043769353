import { useState, useLayoutEffect, useEffect } from 'react';
import styles from '../styles/body.module.scss'
import btnStyles from '../styles/buttons.module.scss'
import Button from '@mui/material/Button';
import axios from '../api/axios';
import useAxiosFunction from '../hooks/useAxiosFunction';
import {Box, TextField} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CircularProgress from '@mui/material/CircularProgress';
import{BottomNavigation, BottomNavigationAction} from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';

export default function UsersInformation({siteId,  userId, setUserId, firstName, setFirstName, lastName, setLastName, operativeEmployer, setOperativeEmployer, 
  drivingToSite, setDrivingToSite, asDriver, setAsDriver, isValid, setIsValid, buttonToShow, setButtonToShow, askDrivingQue, setAskDrivingQue,
  signIn, setSignIn, signOut, setSignOut, setIsDrivingToday}) {

  const navigate = useNavigate();

  function goBack(){
    setUserId(null);
    navigate(-1);
}
 const [progress, setProgress] = useState(false);
  const [response, error, loading, axiosFetch] = useAxiosFunction();
  const [result, setResult] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [invalidReasoning, setInvalidReasoning] = useState(false);

  const [imageUrl, setImageUrl] = useState("");

  useLayoutEffect(() => {
    populateUserInfo();
  }, [userId]);

  useLayoutEffect(() => {
    populateAction();
  }, [result]);

  const populateUserInfo = async e => {
    setProgress(true);
    const bodyObj = JSON.stringify({
        "id":userId,
        "siteId":siteId

    });
      try {
          await axiosFetch({
              axiosInstance: axios,
              method: 'post',
              url: '/getUserInformation',
              requestConfig: {
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  data: bodyObj
              }

          })
      } catch (err) {
          console.log(err);

      }
      setResult(true);
  }

  function populateAction(){
    
    setFirstName(response.firstName);
    setLastName(response.lastName);
    setOperativeEmployer(response.operativeEmployer);
    setIsValid(response.validAccount);
    setDrivingToSite(response.drivingToSite);
    setAsDriver(response.asDriver);
    setInvalidReasoning(response.invalidReason);
    setButtonToShow(response.buttonToShow);
    setAskDrivingQue(response.askIsDrivingQue);

    if(response != []){
      setImageUrl(response.imageUrl)
    }

    console.log(response);
    console.log(invalidReasoning);
    if(response.firstName != undefined){
      setProgress(false);
    }
  }


  const checkIfValid = async e =>{
    
    if(isValid){
      //navigate to next page
      setShowWarning(false);

      nextPage();
    }
    else{
      //display error message and hide buttons
      setShowWarning(true);
      //send email
      const bodyObj = JSON.stringify({
        "id":userId,
        "siteId":siteId

    });

    try {
        await axiosFetch({
          axiosInstance: axios,
          method: 'post',
          url: '/sendInvalidEmail',
          requestConfig: {
            headers: {
              'Content-Type': 'application/json',
            },
            data: bodyObj
          }

        })
      } catch (err) {
        console.log(err);

      }

    }

  }


  const handleImageError = (e) => {
    e.target.onerror = null; 
    e.target.src = "/PhotoUpload.svg"
}

  const nextPage = async e  =>{

    //determine navigation

    if(buttonToShow == 'sign_in'){
      setSignIn(true);
      setSignOut(false);
      if(askDrivingQue){
        navigate("/driving");
      }
      else{
        setIsDrivingToday(false);
        navigate("/site-rules");
      }
    }
    else if(buttonToShow == 'sign_out'){
      navigate("/action");

    }


    
  }


  return (
    <div>
      <>
      <Box className={styles.questionH1Top} >
        <h1>Users Information</h1>
        </Box>
      <Box className={styles.questionH1}>
      <Card sx={{ display: 'flex' }}>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        src={`data:image/jpeg;base64,${imageUrl}`}
        onError={handleImageError}
        alt="Profile Picture"
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
          Induction Code: {userId}
          </Typography>
          <Typography component="div" variant="h5">
          {firstName} {lastName}
          </Typography>
          <Typography component="div" variant="h5">
          {operativeEmployer}
          </Typography>

        </CardContent>
      </Box>

    </Card>
      </Box>
      </>
      <Box className={styles.progressBar}>
        {progress && <CircularProgress size="5rem"/>} 
        </Box>
      {showWarning?<><Typography className={styles.warningTop}  component="p" variant="body1">
          Your account is invalid. The reason for this is:</Typography>
          <Typography className={styles.warningList}  component="p" variant="body1">
           {invalidReasoning}
          </Typography>
          <Typography className={styles.warning} component="p" variant="body1">
          Please update your Dandara Induct account or speak to a member of the site team.
          </Typography>
          <Box className={btnStyles.singleButtonContainer} >
              <Button  startIcon={<HomeIcon />} className={btnStyles.backSingleBtn} size="large" variant="contained" onClick={() => goBack()}>Back to start</Button>

          </Box>
          <Box className={btnStyles.bottomNavContainer}>
              <BottomNavigation sx={{
                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  height:'70px',
                  zIndex: '1000'
              }} showLabels>
                  <BottomNavigationAction id="navBackButton" label='Back' icon={<ArrowBackIosNewIcon />} onClick={() => goBack()} />
              </BottomNavigation>
          </Box>
      </>:
      <>
        <Box className={btnStyles.navbuttons} >
              <Button disabled={progress} className={btnStyles.backBtn} size="large" variant="contained" onClick={() => goBack()}>This is NOT me</Button>
              <Button disabled={progress} className={btnStyles.nextBtn} size="large" variant="contained"
                  onClick={() => checkIfValid()}
              >This is me</Button>

          </Box>
          <Box className={btnStyles.bottomNavContainer}>
              <BottomNavigation sx={{
                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  height:'70px',
                  zIndex: '1000'
              }} showLabels>
                  <BottomNavigationAction disabled={progress} id="navBackButton" label='This is NOT me'  onClick={() => goBack()} />
                  <BottomNavigationAction disabled={progress} id="navNextButton" label='This is me' onClick={() => nextPage()} />
              </BottomNavigation>
          </Box>
      </>
  }
    </div>
  );
}

